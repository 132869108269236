@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Open+Sans:wght@400;600;700&display=swap');

body {
  @apply bg-gray-100;
}

.sample-chapter {
  font-family: 'Merriweather', serif;
  color: #333;
  @apply font-serif leading-relaxed;
}

.sample-chapter h2 {
  font-family: 'Open Sans', sans-serif;
  @apply text-3xl font-bold mb-6 mt-8 text-gray-800;
}

.sample-chapter p {
  @apply mb-0;
}

.sample-chapter .book-paragraph {
  @apply mb-0 indent-8;
}

.sample-chapter .first-paragraph {
  @apply mb-0 indent-0;
}

.sample-chapter .first-paragraph::first-letter {
  @apply float-left text-5xl leading-8 mr-2 mt-2;
  initial-letter: 3;
  -webkit-initial-letter: 3;
  color: #8B4513;
  font-weight: bold;
}

@media (max-width: 640px) {
  .sample-chapter {
    font-size: 16px;
    line-height: 1.6;
  }
}

.marketing-copy {
  white-space: normal;
  word-break: break-word;
}

.marketing-copy > div > p {
  margin-bottom: .75em;
}

.marketing-copy b {
  display: block;
  margin-top: .5em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.marketing-copy i {
  display: block;
  white-space: pre-line;
  margin-bottom: 1em;
  font-style: italic;
}

/* Custom styles for book paragraphs */
@layer components {
  .book-paragraph {
    @apply mb-0 indent-8;
  }
  
  .first-paragraph {
    @apply mb-0 indent-0;
  }

  .first-paragraph::first-letter {
    @apply float-left text-5xl leading-8 mr-2 mt-2;
  }
}